#side-bar {
    overflow: hidden;
}

.logged-user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logged-user {
    display: flex;
    align-items: center;
}

.logged-user .username {
    flex-grow: 1;
}

.logged-user img {
    aspect-ratio: 1 / 1;
    max-height: 50px;
}

.logged-user span {
    font-weight: 600;
    font-size: 120%;
    margin-left: 0.25rem;
    cursor: default;
}

.dx-list-item-selected {
    background-color: var(--bg-active) !important;
    color: var(--text-active) !important;
}
