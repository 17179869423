.template-editor {
    min-height: 75vh;
}

.dx-tab-selected {
    background-color: var(--bg-active) !important;
    color: var(--text-active) !important;
}

.popup-footer-buttons .footer-button {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0.5rem;
}

.popup-footer-buttons .dx-item.dx-box-item {
    justify-content: end !important;
}
.popup-footer-buttons .dx-box-item-content.dx-box-flex {
    flex-grow: 0 !important;
}

.dx-toast-stack {
    margin-left: 10px;
    align-items: stretch;
}

/* Readonly textbox of values component */
.d-flex {
    display: flex;
}
.d-flex .text-center input:read-only {
    text-align: center;
}

/* Border between 2 readonly textboxs */
.value-header + .value-header {
    border-left: 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.value-header:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

/* Sup tag show mark in title of tab */
.main-tab .dx-tab .dx-tab-text {
    padding-left: 1rem;
    padding-right: 1rem;
}
.main-tab .title-sup {
    position: relative;
    left: 5px;
    padding: 1px 5px;
    background: var(--bg-active);
    border-radius: 50%;
    color: white;
}
