@keyframes skeleton-loading {
    0% {
        outline: transparent 1px solid;
    }

    50% {
        outline: #1ca8dd88 1px solid;
    }

    100% {
        outline: #1ca8ddff 1px solid;
    }
}

@keyframes loosing {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.75;
    }

    100% {
        opacity: 0.5;
    }
}

.mention-replacer {
    display: inline-block;
    position: relative;
    border-width: 1px;
    border-style: dashed;
    border-color: transparent;
    padding: 0 3px;
    margin: 0 3px;
    cursor: pointer;
}
.mention-replacer:not([data-value]) {
    cursor: wait;
    animation: skeleton-loading 0.3s linear;
}

.mention-replacer[data-empty] {
    cursor: not-allowed;
    opacity: 0.5;
    animation: loosing 0.3s linear;
}

.mention-replacer,
.mention-replacer * {
    font-size: inherit;
}

.mention-replacer.invisible,
.mention-replacer:hover {
    border-color: #4d4d4d;
}

.mention-replacer.invisible .content {
    display: none;
}

.mention-replacer .action {
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: none;
    background-color: #4d4d4d;
}

.mention-replacer .action * {
    position: relative;
    z-index: -1;
}

.mention-replacer.invisible .action,
.mention-replacer:hover .action {
    display: block;
}

.mention-replacer.invisible .action {
    position: relative;
    background-color: transparent;
}

.mention-replacer[data-value] .action:focus,
.mention-replacer[data-value] .action:active {
    background-color: black !important;
}
