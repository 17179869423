#firebase-ui:empty {
    display: none;
}

#firebase-ui:not(:empty) {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.logged-user-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logged-user {
    display: flex;
    align-items: center;
}
