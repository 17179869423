* {
    font-size: 1rem;
}
body {
    padding: 0;
    margin: 0;
}

:root {
    --text-color: #dedede;
    --bg-active: #1ca8dd;
    --text-active: #2a2a2a;
}

#root:not(:empty) {
    min-height: 100vh;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.pr-1 {
    padding-right: 0.25rem !important;
}
.pr-2 {
    padding-right: 0.5rem !important;
}
.pr-3 {
    padding-right: 1rem !important;
}
.pr-4 {
    padding-right: 1.5rem !important;
}
.pr-5 {
    padding-right: 3rem !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}
.pl-2 {
    padding-left: 0.5rem !important;
}
.pl-3 {
    padding-left: 1rem !important;
}
.pl-4 {
    padding-left: 1.5rem !important;
}
.pl-5 {
    padding-left: 3rem !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.h-100 {
    height: 100% !important;
}
.text-center {
    text-align: center;
}

.d-none {
    display: none !important;
}

.custom-scrollbar {
    overflow-y: scroll !important;
    overflow-x: auto !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: black;
    border: 1px solid dimgray;
}

a[href] {
    text-decoration: none;
    color: var(--text-color);
}
a[href]:hover {
    color: var(--bg-active);
}
a[href][target="_blank"]::after {
    font-family: DXIcons;
    content: "\f05f";
    padding-left: 3px;
}

table:not([class]) {
    margin: 0 auto;
    table-layout: auto;
}

.dx-toast-content {
}

.dx-toast-content .dx-toast-icon {
    width: 20px;
    height: 20px;
}

.dx-toast-content .dx-toast-message {
    font-size: inherit;
}
